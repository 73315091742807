
.quick-edit-cta {
  z-index: var(--z-top-1);

  &.inline-block {
    margin-inline-end: 1em;
  }

  &.absolute-outside-top {
    display: inline-block;
    position: absolute;
    top: calc(v-bind(elementSizeCssUnitNegative) - 0.75em + v-bind(offsetPx));
    inset-inline-start: 50%;
    margin-inline-start: v-bind(elementHalfSizeCssUnitNegative);
  }

  &.absolute-top {
    display: inline-block;
    position: absolute;
    top: calc(0.75em + v-bind(offsetPx));
    inset-inline-start: 50%;
    margin-inline-start: v-bind(elementHalfSizeCssUnitNegative);
  }

  &.absolute-outside-diagonal {
    display: inline-block;
    position: absolute;
    top: calc(v-bind(elementSizeCssUnitNegative) - 0.75em + v-bind(offsetPx));
    inset-inline-start: calc(
      v-bind(elementSizeCssUnitNegative) - 0.75em + v-bind(offsetPx)
    );
  }

  &.absolute-outside {
    display: inline-block;
    position: absolute;
    top: 0;
    inset-inline-start: calc(
      v-bind(elementSizeCssUnitNegative) - 0.75em + v-bind(offsetPx)
    );
  }

  &.absolute-inside {
    display: inline-block;
    position: absolute;
    top: calc(0.75em + v-bind(offsetPx));
    inset-inline-start: calc(0.75em + v-bind(offsetPx));
  }

  &.absolute-border {
    display: inline-block;
    position: absolute;
    top: calc(v-bind(elementHalfSizeCssUnitNegative) + v-bind(offsetPx));
    inset-inline-start: calc(v-bind(elementHalfSizeCssUnitNegative) + v-bind(offsetPx));
  }

  &.absolute-border-top {
    display: inline-block;
    position: absolute;
    top: calc(v-bind(elementHalfSizeCssUnitNegative) + v-bind(offsetPx));
    inset-inline-start: 50%;
    margin-inline-start: v-bind(elementHalfSizeCssUnitNegative);
  }
}
